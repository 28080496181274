<template>
  <div class="info">
    <nav-bar show-back="1">
      <div class="nav-title">PUSAT PRIBADI</div>
    </nav-bar>
    <div class="kong"></div>
    <van-row class="row-info">
      <van-col span="6">
        <img :src="logoimg" class="infoavatar" v-if="!avatar" />
        <van-image
          v-if="avatar"
          round
          width="80"
          height="80"
          :src="avatar"
          style="background: #eff5fb"
        />
      </van-col>
      <van-col span="10">
        <!-- 未登陆 -->
        <div v-if="!username" class="islogin">Belum masuk</div>
        <div class="mineinfo" v-if="username">
          <div class="mine-name">
            <h2 class="username">{{ username ? username : "Tidak diatur" }}</h2>
            <p class="userid">ID：{{ userid ? userid : "Tidak ada" }}</p>
          </div>
        </div>
      </van-col>
      <van-col span="6">
        <div class="infoqia" @click="masuk">Masuk</div>
      </van-col>
    </van-row>
    <div class="options">
      <van-cell-group :style="{borderRadius: '10px'}">
        <van-cell
          value="Mempersiapkan"
          icon="setting-o"
          is-link
          center
          style="padding: 10px;borderRadius: 10px"
        />
        <van-cell
          value="Daftar pertanyaan"
          icon="records"
          is-link
          center
          style="padding: 10px;borderRadius: 10px"
          @click="gotosurvey"
        />
        <van-cell
          value="Tentang kami"
          icon="question-o"
          is-link
          center
          style="padding: 10px;borderRadius: 10px"
          @click="gotoaboutinfo"
        />
        <van-cell
          value="Hubungi kami"
          icon="phone-circle-o"
          is-link
          center
          style="padding: 10px;borderRadius: 10px"
            @click="gotoacontackus"
        />
        <!-- <van-cell
          value="Pesananku"
          icon="orders-o"
          is-link
          center
          style="padding: 10px;borderRadius: 10px"
            @click="gotoOrders"
        />
        <van-cell
          value="Alamat pengiriman"
          icon="location-o"
          is-link
          center
          style="padding: 10px;borderRadius: 10px"
            @click="gotoLocation"
        /> -->
      </van-cell-group>
      <div class="bgimg">
        <img src="./icon/logo.png" alt="" />
        <!-- 东南亚拼团电商开拓者 -->
        <h4 class="bgtitel">Pelopor E-commerce Grup Asia Tenggara</h4>
      </div>
    </div>
    <bottom isactive="4"></bottom>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import Bottom from "../../components/bottom";
import NavBar from "../../components/navBar";
import logoimg from "./icon/logo.png";
import { Toast } from "vant";
export default {
  components: { Bottom, NavBar },
  data() {
    return {
      logoimg: logoimg,
      username: storage.get("username"),
      token: storage.get("token"),
      userid: storage.get("userid"),
      avatar: storage.get("avatar"),
    };
  },
  methods: {
    gotoaboutinfo() {
      this.$router.push("/groups/mine/abouts");
    },
    gotosurvey() {
      //this.$router.push("/groups/mine/survey");
    },
    gotoacontackus(){
       this.$router.push('/groups/mine/contackus');
    },
    gotoOrders(){
       this.$router.push('/groups/mine/orders');
    },
    gotoLocation(){
       this.$router.push('/groups/mine/location');
    },
    masuk() {
      Toast({
        message: "Masuk belum dibuka",
        icon: "smile-comment-o",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  padding-bottom: pxTo(48+20);

  @extend %page;
  background-color: $c1;
}
.islogin {
  margin-top: pxTo(8);
  font-size: pxTo(16);
  font-weight: 500;
  color: #fff;
  margin-bottom: pxTo(8);
  font-family: "Microsoft Yahei", "SimHei", "SimSun", "Lucida Console",
    "Courier New", monospace;
}
.infoavatar {
  display: block;
  width: pxTo(84);
  height: pxTo(84);
  border-radius: 50%;
  background: #eff5fb;
}
.van-cell__left-icon,
.van-cell__right-icon {
  height: pxTo(24);
  font-size: pxTo(18);
  line-height: pxTo(24);
  margin-right: pxTo(12);
}
.van-cell {
  color: #ff8e33;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #c7c5cf;
}
.username {
  margin-top: pxTo(8);
  font-size: pxTo(22);
  font-weight: 500;
  color: #fff;
  margin-bottom: pxTo(8);
  font-family: "Microsoft Yahei", "SimHei", "SimSun", "Lucida Console",
    "Courier New", monospace;
}
.userid {
  font-size: pxTo(14);
  font-weight: 500;
  color: #fff;
  margin-bottom: pxTo(8);
  font-family: "Microsoft Yahei", "SimHei", "SimSun", "Lucida Console",
    "Courier New", monospace;
}
.infoqia {
  margin-top: pxTo(8);
  width: pxTo(80);
  height: pxTo(25);
  line-height: pxTo(25);
  text-align: center;
  color: #fff;
  background: $bg1;
  border-radius: pxTo(18);
  margin-left: pxTo(18);
}
.van-grid-item {
  color: $c1;
}
.kong {
  width: 100%;
  height: pxTo(16);
}
.row-info {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: pxTo(8);
  margin-bottom: pxTo(16);
}
.grid {
  width: 100%;
  min-height: pxTo(100);
  height: auto;
  border-radius: pxTo(15);
  background: #f5f5f5;
  padding: pxTo(8);
  margin-bottom: pxTo(20);
}
.options {
  padding: pxTo(10);
  // width: 100%;
  min-height: pxTo(420);
  height: auto;
  border-radius: pxTo(15);
  background: #eff5fb;
  padding: pxTo(8);
  margin: 0 0.5rem;
}
.bgimg {
  height: pxTo(200);
  width: 100%;
  background: #eff5fb;
  position: relative;
  img {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 5.85rem;
    height: 5.85rem;
    opacity: 0.15;
  }
  .bgtitel {
    display: block;
    text-align: center;
    width: 100%;
    color: #969799;
    font-size: pxTo(14);
    font-weight: 400;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}
</style>